import React, { ReactElement, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Menu,
  MenuProps, Stack, Theme,
  Typography
} from '@mui/material';
import { lighten, useTheme, styled } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { ParamFilter } from '../../types/types';
import { FilterParamsManager } from '../entity/list';
import { mainRadius } from '../../themes/theme';
import CustomButton from '../button/button';
import { sidebarStatus } from '../../hooks/datatabs';
import SearchAutoComplete from '../search/searchautocomplete';

export const useFiltersStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    height: 'min-content',
    marginLeft: '-24px',
    padding: '0px 0px 0px 24px',
    alignItems: 'center',
    overflowX: 'auto',
    overflowY: 'hidden'
  },
  fixedContainer: {
    position: 'fixed',
    top: 'calc(56px + 64px)',
    right: '0px',
    padding: '8px 0px',
    paddingLeft: '24px',
    paddingRight: '24px',
    backgroundColor: theme.palette.background.default,
    border: 'none',
    borderRadius: '0px !important',
    width: 'auto',
    opacity: 1,
    zIndex: 998,
    transition: 'backgroundColor 200ms ease-in-out'
  },
  button: {
    height: '30px',
    minWidth: 'min-content',
    borderRadius: mainRadius,
    color: lighten(theme.palette.text.primary, 0.3),
    borderColor: lighten(theme.palette.text.primary, 0.3),
    marginRight: '12px',
    padding: '2px 6px 2px 10px',
    textTransform: 'none',
    whiteSpace: 'nowrap',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      color: lighten(theme.palette.text.primary, 0),
      borderColor: lighten(theme.palette.text.primary, 0)
    },
    '&:focus': {
      borderColor: lighten(theme.palette.text.primary, 0.3)
    }
  },
  clearButton: {
    height: '30px',
    minWidth: 'min-content',
    color: lighten(theme.palette.text.primary, 0.3),
    border: 'none',
    textTransform: 'none',
    whiteSpace: 'nowrap',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      color: lighten(theme.palette.text.primary, 0),
      backgroundColor: 'transparent'
    }
  },
  filtersNumber: {
    backgroundColor: theme.palette.primary.main,
    marginLeft: '8px',
    width: '20px',
    height: '20px',
    paddingTop: '2px',
    borderRadius: mainRadius,
    marginRight: '-6px',
    color: theme.palette.primary.contrastText
  },
  select: {
    padding: '0px 14px'
  }
}));

function FilterMenu(props: MenuProps): ReactElement {
  return (
    <Menu
      elevation={0}
      anchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      {...props}
    />
  );
}

export const FiltersStyledMenu = styled(FilterMenu)(() => ({
  '& .MuiPaper-root': {
    borderRadius: mainRadius,
    backgroundColor: useTheme().palette.background.paper,
    boxShadow: '0 1.3px 5.3px rgba(0, 0, 0, 0.028), 0 4.5px 17.9px rgba(0, 0, 0, 0.042), 0 20px 80px rgba(0, 0, 0, 0.07)',
    padding: 0,
    paddingTop: '0px',
    marginTop: '12px'
  }
}));

export interface FilterProps {
  filter: ParamFilter,
  filterParamsManager: FilterParamsManager,
  update: boolean
}

export function MenuFilter(props: FilterProps): ReactElement {
  const theme = useTheme();
  const classes = useFiltersStyles(theme);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLButtonElement>(null);
  const [arrayValues, setArrayValues] = React.useState<Array<string>>(props.filterParamsManager.getFilter(props.filter.id)?.value.split(',') || []);

  const { filterParamsManager } = props;

  const possibleValues = props.filter.values ? props.filter.values : [];

  useEffect(() => {
    props.update && setArrayValues([]);
  }, [props.update]);

  useEffect(() => {
    if (props.filter.values && arrayValues.length !== 0) {
      filterParamsManager.onFilterChanged(props.filter.id, arrayValues.join(','));
    } else {
      filterParamsManager.onFilterRemoved(props.filter.id);
    }
  }, [arrayValues]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setArrayValues([...arrayValues, event.target.name]);
    } else if (arrayValues.length === 1) {
      setArrayValues([]);
    } else {
      setArrayValues(arrayValues.filter(item => item !== event.target.name));
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        variant='outlined'
        onClick={handleClick}
        color='primary'
        className={classes.button}
        size='small'
        endIcon={anchorEl ? <ExpandLessRoundedIcon/> : <ExpandMoreRoundedIcon/>}
        disabled={possibleValues.length < 1}
      >
        {props.filter.label}
        { arrayValues.length > 0 &&
          <Typography variant='body2' className={classes.filtersNumber}>
            {arrayValues.length === possibleValues.length ? 'all' : arrayValues.length}
          </Typography>
        }
      </Button>
      <FiltersStyledMenu
        disableScrollLock
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        variant='selectedMenu'
      >
        <FormGroup>
          {possibleValues.map(value => (
            <FormControlLabel
              key={value.value || value.label}
              className={classes.select}
              control={
                <Checkbox
                  checked={arrayValues.find(val => val === (value.value || value.label)) === (value.value || value.label)}
                  onChange={handleChange}
                  name={value.value || value.label}
                  color='primary'
                />
              }
              label={value.count ? `${value.label} (${value.count})` : value.label}
            />
          ))}
        </FormGroup>
      </FiltersStyledMenu>
    </>
  );
}

export function BooleanFilter(props: FilterProps): ReactElement {
  const theme = useTheme();
  const classes = useFiltersStyles(theme);
  // const [anchorEl, setAnchorEl] = React.useState<null | HTMLDivElement>(null);
  const [value, setValue] = React.useState<boolean | undefined>(props.filterParamsManager.getFilter(props.filter.id)?.value === 'true' || undefined);

  const { filterParamsManager } = props;

  useEffect(() => {
    props.update && setValue(false);
  }, [props.update]);

  useEffect(() => {
    if (value !== undefined) {
      filterParamsManager.onFilterChanged(props.filter.id, `${  value}`);
    } else {
      filterParamsManager.onFilterRemoved(props.filter.id);
    }
  }, [value]);

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.checked) {
  //     setArrayValues([...arrayValues, event.target.name]);
  //   } else {
  //     if (arrayValues.length === 1) {
  //       setArrayValues([]);
  //     } else {
  //       setArrayValues(arrayValues.filter(item => item !== event.target.name));
  //     }
  //   }
  // };

  const handleClick = () => {
    if (value) {
      setValue(undefined);
    } else {
      setValue(true);
    }
  };

  return (
    <>
      <Button
        onClick={handleClick}
        color='primary'
        variant='outlined'
        className={classes.button}
        style={{ paddingRight: '10px' }}
        size='small'
        // endIcon={anchorEl ? <ExpandMoreOutlinedIcon/> : <ChevronRightOutlinedIcon/>}
      >
        {props.filter.label}
        { value &&
          <Typography variant='body2' className={classes.filtersNumber}>
            <CheckRoundedIcon fontSize='small' style={{ transform: 'scale(0.8)', marginTop: '-2px' }} />
          </Typography>
        }
      </Button>
    </>
  );
}

export function CheckboxFilter(props: FilterProps): ReactElement {
  const theme = useTheme();
  const classes = useFiltersStyles(theme);
  const [value, setValue] = React.useState<boolean | undefined>(props.filterParamsManager.getFilter(props.filter.id)?.value === 'true' || undefined);

  const { filterParamsManager } = props;

  useEffect(() => {
    props.update && setValue(false);
  }, [props.update]);

  useEffect(() => {
    if (value) {
      filterParamsManager.onFilterChanged(props.filter.id, `${value}`);
    } else {
      filterParamsManager.onFilterChanged(props.filter.id, 'undefined');
    }
  }, [value]);

  const handleClick = () => {
    if (value) {
      setValue(false);
    } else {
      setValue(true);
    }
  };

  return (
    <>
      <FormGroup>
        <FormControlLabel
          key={props.filter.label}
          className={classes.select}
          control={
            <Checkbox checked={value} name={props.filter.label} color='primary' onClick={handleClick}/>
          }
          label={props.filter.label}
          style={{ height: '30px', paddingRight: 0 }}
        />
      </FormGroup>

    </>
  );
}

export function DateFilter(props: FilterProps & { range: 'start' | 'end' }): ReactElement {
  const theme = useTheme();
  const classes = useFiltersStyles(theme);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLButtonElement>(null);
  const [value, setValue] = React.useState<string | undefined>(props.filterParamsManager.getFilter(props.filter.id)?.value || undefined);

  const { filterParamsManager } = props;

  const possibleValues = props.filter.values ? props.filter.values : [];

  useEffect(() => {
    props.update && setValue(undefined);
  }, [props.update]);

  useEffect(() => {
    if (value) {
      filterParamsManager.onFilterChanged(props.filter.id, `${value}`);
    } else {
      filterParamsManager.onFilterRemoved(props.filter.id);
    }
  }, [value]);

  const handleChange = (date: dayjs.Dayjs | null) => {
    const startDate = date ? date?.toDate().toDateString() : undefined;

    setValue(startDate);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const convertArrayValuesToDate = () => {
    if (value) {
      return new Date(value);
    }

    return null;
  };

  const resetValue = () => {
    setValue(undefined);
  };

  return (
    <>
      <Button
        variant='outlined'
        onClick={handleClick}
        color='primary'
        className={classes.button}
        size='small'
        endIcon={anchorEl ? <ExpandLessRoundedIcon/> : <ExpandMoreRoundedIcon/>}
        disabled={possibleValues.length < 1}
      >
        {props.filter.label}
        { value &&
          <Typography variant='body2' className={classes.filtersNumber}>
            1
          </Typography>
        }
      </Button>
      <FiltersStyledMenu
        disableScrollLock
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        variant='selectedMenu'
      >
        <FormGroup>
          <Stack width='270px' display='flex' flexDirection='column' justifyContent='center' spacing={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack display='flex' flexDirection='row' alignItems='center'>
                <DesktopDatePicker
                  label={props.filter.label}
                  // maxDate={dayjs(convertArrayValuesToDate()) || null}
                  value={dayjs(convertArrayValuesToDate()) || null}
                  onChange={valueChange => handleChange(valueChange)}
                  format='YYYY/MM/DD'
                  sx={{ padding: 1 }}
                />
                <IconButton
                  onClick={resetValue}
                  sx={{ height: '40px' }}
                >
                  <ClearRoundedIcon color='error'/>
                </IconButton>
              </Stack>
            </LocalizationProvider>
          </Stack>
        </FormGroup>
      </FiltersStyledMenu>
    </>
  );
}

export function defaultFilterComponentFactory(props: FilterProps): ReactElement | undefined {
  const { filter } = props;
  switch (filter.type) {
  case 'multiSelect':
    return (<MenuFilter key={filter.id} {...props} />);
  case 'boolean':
    return (<BooleanFilter key={filter.id} {...props} />);
  case 'search':
    return (<SearchAutoComplete key={filter.id} {...props} />);
  case 'checkbox':
    return (<CheckboxFilter key={filter.id} {...props} />);
  case 'startDate':
    return (<DateFilter key={filter.id} {...props} range='start'/>);
  case 'endDate':
    return (<DateFilter key={filter.id} {...props} range='end'/>);
  default:
    return undefined;
  }
}

export interface GenericFiltersProps {
  filters: Array<ParamFilter>,
  filterParamsManager: FilterParamsManager,
  filterComponentFactory?: (props: FilterProps) => ReactElement | undefined,
  fixed?: boolean
}

function GenericFilters(props: GenericFiltersProps): ReactElement {
  const theme = useTheme();
  const classes = useFiltersStyles(theme);
  const { t } = useTranslation();
  const [update, setUpdate] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const sidebarStatusGetter = sidebarStatus();
  const [fixed, setFixed] = useState(false);
  const scrollHandler = () => {
    const height = Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight
    );
    if (props.fixed && window.scrollY > (56 + 64 - 1) && height > (window.innerHeight + 500 + (containerRef.current?.clientHeight || 360))) {
      setFixed(true);
    } else if (window.scrollY < (56 + 64)) {
      setFixed(false);
    }
  };

  useEffect(() => {
    setUpdate(false);
  }, [update]);

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler, true);

    return () => {
      window.removeEventListener("scroll", scrollHandler, true);
    };
  }, []);

  if (props.filters.length < 1) {
    return (<div style={{ marginTop: '12px' }} />);
  }

  // Use the default component factory if none is provided in the props
  const filterComponentFactory = (props.filterComponentFactory) ? props.filterComponentFactory : defaultFilterComponentFactory;

  const handleReset = () => {
    props.filters.forEach((filter) => {
      props.filterParamsManager.onFilterRemoved(filter.id);
    });
    props.filterParamsManager.onFilterRemoved('search');
    setUpdate(true);
  };

  const filterComponents = props.filters.map(f => ({ filter: f, filterParamsManager: props.filterParamsManager, update })) // make FilterProps
    .map(filterComponentFactory) // Map to the appropriate component using the component factory
    .filter(c => !!c);

  return (
    <>
      <div
        ref={containerRef}
        className={fixed ? classes.fixedContainer : classes.container}
        style={ fixed ?
          {
            left: sidebarStatusGetter ? '270px' :'96px',
            transition: 'left 200ms ease-in'
          } :
          {
            transition: 'left 200ms ease-out'
          }
        }
      >
        <Grid container spacing={2} style={{ width: '100%' }}>
          {fixed &&
            <Grid item>
              <FilterListRoundedIcon />
            </Grid>
          }
          {filterComponents.map(filter => (
            <Grid item key={filter?.key} style={{ marginRight: '-12px' }}>
              {filter}
            </Grid>
          ))}
          <Grid item>
            <CustomButton disableRipple variant='text' small onClick={handleReset} className={classes.clearButton}>{t('filters.clearFilters')}</CustomButton>
          </Grid>
        </Grid>
      </div>
      {props.fixed && fixed &&
        <Box
          style={
            { width: '100%', height: fixed ? 32 : 0 }
          }
        />
      }
    </>
  );
}

export default GenericFilters;
