import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Stack } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useKeycloak } from '@react-keycloak/web';
import { getSearchParamsContext, SearchParamsAccess, SearchParamsContextProvider } from '../../../components/params';
import { ApiFilter, InvestMgr } from '../../../types/types';
import { AssetMgrTable } from './table';
import { EntityListDataProvider, FilterParamsManager, ListRendererMap, MultiModeList } from '../../../components/entity/list';
import { AssetMgrService } from '../../../services/assetmgr';
import CustomPagination from '../../../components/layout/pagination/pagination';
import InvestMgrFilters from './filters';
import TableSkeleton from '../../../components/layout/loading/tableskeleton';
import checkRoles from '../../../components/authentification/checkRoles';

class AssetMgrListDataProvider extends EntityListDataProvider<InvestMgr> {
  constructor(searchParamsAccess: SearchParamsAccess) {
    super(searchParamsAccess, new AssetMgrService());
  }
}

export function AssetMgrListingHeader(props: {filterParamsManager: FilterParamsManager, apiFilters?: ApiFilter[]}): ReactElement {
  const keycloak = useKeycloak();
  const isAdmin = checkRoles(['admin'], keycloak.keycloak);
  const navigate = useNavigate();

  return (
    <Stack direction='row'>
      <InvestMgrFilters {...props} />
      {isAdmin &&
        <Button
          size='small'
          variant='outlined'
          color='primary'
          onClick={() => {
            navigate('/admin/create/assetMgr');
          }}
          startIcon={<AddRoundedIcon />}
          sx={{
            minWidth: '215px',
            maxHeight: '50px'
          }}>
            Create Asset Manager
        </Button>
      }
    </Stack>
  );
}

export function AssetMgrMultiModeList(): ReactElement {
  const searchParamsAccess = getSearchParamsContext();
  const listDataProvider = new AssetMgrListDataProvider(searchParamsAccess);
  let singleReviewsColumns = true;

  singleReviewsColumns = searchParamsAccess.getParam('f_singleColsReviews') === 'true';

  if (searchParamsAccess.getParam('f_singleColsReviews') === null) {
    searchParamsAccess.updateParam('f_singleColsReviews', 'true');
    singleReviewsColumns = true;
  }

  function renderEmpty() {
    return (
      <div style={{ marginTop: '40px' }}>No Asset Manager...</div>
    );
  }

  function renderLoading() {
    return (
      <TableSkeleton nbColumns={4} />
    );
  }

  function renderAsTable(eltList: Array<InvestMgr>, totalCount: number) {
    return (
      <>
        <AssetMgrTable list={eltList} paramsSort={listDataProvider.sortParamsManager} singleReviewCol={singleReviewsColumns} />
        <CustomPagination paramsPager={listDataProvider.paramsPager} totalItems={totalCount} />
      </>
    );
  }

  function renderAsCards(eltList: Array<InvestMgr>, totalCount: number) {  // eslint-disable-line @typescript-eslint/no-unused-vars
    return (
      <>
        <div>Cards List here</div>
        <div>Pagination goes here...</div>
      </>
    );
  }

  const renderers: ListRendererMap<InvestMgr> = {
    'table': renderAsTable,
    'cards': renderAsCards
  };

  return (
    <MultiModeList
      listDataProvider={listDataProvider}
      modes={['table' /* 'cards' */]}
      defaultMode='table'
      renderers={renderers}
      renderEmpty={() => renderEmpty()}
      renderLoading={() => renderLoading()}
      filtersComponent={AssetMgrListingHeader}
    />
  );
}

function AssetMgrListingPage(): ReactElement {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = React.useState<URLSearchParams>(new URLSearchParams(window.location.search));

  return (
    <SearchParamsContextProvider value={new SearchParamsAccess(searchParams, setSearchParams, navigate)}>
      <AssetMgrMultiModeList />
    </SearchParamsContextProvider>
  );
}

export default AssetMgrListingPage;
