import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ActionLog, ParamFilter } from '@deecision/deeligenz-interfaces';
import AdminAuditTrailTable from './adminaudittrailtable';
import { ListRendererMap, MultiModeList } from '../../entity/list';
import { getSearchParamsContext, SearchParamsContextProvider, SearchParamsAccess } from '../../params';
import AdminAuditTrailFilters, { ParamFilterContext } from '../../audittrail/components/adminaudittrailfilters';
import AdminAuditTrailDataProvider from '../../audittrail/adminaudittrailprovider';
import CustomPagination from '../../layout/pagination/pagination';
import AdminAuditTrailService from '../../../services/AdminAuditTrailService';

interface AuditTrailProps {
  entityType: string,
  id: string
}

export function AdminAuditTrailMultiModeList(props: AuditTrailProps): ReactElement {
  const searchParamsAccess = getSearchParamsContext();
  const adminAuditTrailService = new AdminAuditTrailService(props.entityType, props.id);
  const listDataProvider = new AdminAuditTrailDataProvider(props.entityType, searchParamsAccess, props.id, 50);
  const [auditTrailFilters, setAuditTrailFilters] = useState<ParamFilter[]>([]);

  const renderEmpty = () => (
    <>
      <div style={{ marginTop: '40px' }}>No events...</div>
    </>
  );

  const renderLoading = () => (
    <div style={{ marginTop: '40px' }}>Loading...</div>
  );

  const renderAsTable = (eltList: ActionLog[], totalCount: number): ReactElement => (
    <>
      <AdminAuditTrailTable data={eltList} paramsSort={listDataProvider.sortParamsManager}/>
      <CustomPagination paramsPager={listDataProvider.paramsPager} totalItems={totalCount} />
    </>
  );

  const renderers: ListRendererMap<ActionLog> = {
    'table': (esList, totalCount) => renderAsTable(esList, totalCount)
  };

  // move this to adminAuditTrailFilters.tsx
  const setDynamicFilters = () => {
    adminAuditTrailService.getDynamicFilters().then((res) => {
      if (res.data) {
        const actionsAsApiFilterValue = res.data.parsedFilters.actions.map(action => ({
          label: action,
          value: action
        }));
        const usersAsApiFilters = res.data.parsedFilters.users.map(users => ({
          label: users,
          value: users
        }));
        const entityTypesAsApiFilters = res.data.parsedFilters.entityType.map(entityType => ({
          label: entityType,
          value: entityType
        }));
        const targetTypeAsApiFilters = res.data.parsedFilters.targetType.map(targetType => ({
          label: targetType,
          value: targetType
        }));

        const dynamicFilters: ParamFilter[] = [
          {
            id: 'entityType',
            type: 'multiSelect',
            label: 'Entity Type',
            values: entityTypesAsApiFilters || undefined
          },
          {
            id: 'actionType',
            type: 'multiSelect',
            label: 'Actions',
            values: actionsAsApiFilterValue || undefined
          },
          {
            id: 'targetType',
            type: 'multiSelect',
            label: 'Target Type',
            values: targetTypeAsApiFilters || undefined
          },
          {
            id: 'userName',
            type: 'multiSelect',
            label: 'Users',
            values: usersAsApiFilters || undefined
          }
        ];

        setAuditTrailFilters(dynamicFilters);
      }
    });
  };

  useEffect(() => {
    setDynamicFilters();
  }, []);

  return (
    <ParamFilterContext.Provider value={auditTrailFilters} >
      <MultiModeList
        listDataProvider={listDataProvider}
        modes={['table']}
        defaultMode='table'
        renderers={renderers}
        renderEmpty={renderEmpty}
        renderLoading={renderLoading}
        filtersComponent={AdminAuditTrailFilters}
        actionsService={adminAuditTrailService}
      />
    </ParamFilterContext.Provider>
  );
}

function AdminAuditTrail(props: AuditTrailProps): ReactElement {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const [searchParams, setSearchParams] = React.useState<URLSearchParams>(params);
  const searchParamsAccess = new SearchParamsAccess(searchParams, setSearchParams, navigate);

  return (
    <SearchParamsContextProvider value={searchParamsAccess}>
      <AdminAuditTrailMultiModeList {...props} />
    </SearchParamsContextProvider>
  );
}

export default AdminAuditTrail;