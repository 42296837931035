import { BaseService } from './data';
import { ApiResponse, Nullable } from '../types/types';
import { API } from '../api/api';

export class MorningStarService extends BaseService {

  getBaseUrl() : string {
    return '/api/v1/morningstar';
  }

  getInfoFromMorningStar(isinCode: string): Promise<ApiResponse<Nullable<{fund: unknown, assetMgr: unknown}>>> {
    return API.get(`${this.getBaseUrl()}/info/${isinCode}`)
      .then(res => this.handleResponse<{fund: unknown, assetMgr: unknown}>(res));
  }
}

export default MorningStarService;
