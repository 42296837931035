import { ZonedDateTime, ZoneId, DateTimeFormatter } from '@js-joda/core';
import '@js-joda/timezone';

function DateUtilsConverter(date: string, timezone = 'Europe/Paris', formatOutput = 'epoch'): string {
  // // Parse the ISO string as a ZonedDateTime in UTC
  const zdtUtc = ZonedDateTime
    .parse(date)
    .withZoneSameInstant(ZoneId.of('Etc/UTC'));

  // // Convert to specified timezone or Paris by default
  const zoneId = ZoneId.of(timezone);

  // Return the formatted date depending on requested output
  switch (formatOutput) {
  case 'epoch':
    return zdtUtc.withZoneSameInstant(zoneId).toInstant().toEpochMilli().toString();
  case 'ISO':
    return zdtUtc.withZoneSameInstant(zoneId).format(DateTimeFormatter.ofPattern('yyyy-MM-dd HH:mm:ss'));
  default:
    return zdtUtc.withZoneSameInstant(zoneId).toInstant().toEpochMilli().toString();
  }
}

export default DateUtilsConverter;