import { ActionLog, ApiResponse, Filter, Nullable } from '@deecision/deeligenz-interfaces';
import { API } from '../api/api';
import { AnyParams, BaseEntityService, PaginatedParams } from './data';

// TODO: export in interfaces
interface DynamicFiltersAuditTrail {
  ddFilters: {
    eventUsersPhases: string[],
    eventUsersActivities: string[],
    eventActionsPhases: string[],
    eventActionsActivities: string[],
    entityType: string[],
    eventUsersAll: string[],
    eventActionsAll: string[]
  },
  entityFilters: {
    eventActions: string[],
    eventUsers: string[],
    entityType: string[]
  },
  parsedFilters: {
    entityType: string[],
    actions: string[],
    users: string[],
    targetType: string[]
  }
}

class AdminAuditTrailService extends BaseEntityService<ActionLog> {
  entityType: string;

  _id: string;

  constructor(entityType: string, id: string) {
    super();
    this.entityType = entityType;
    this._id = id;
  }

  getId(): string {
    return this._id;
  }
  
  getBaseUrl(): string {
    return `/api/v1/audittrail`;
  }

  getListInfo(): {url: string, defaultParams: AnyParams} {
    return {
      url: this.getBaseUrl(),
      defaultParams: {}
    };
  }

  getEntityList(itemsPerPage?: number, skip?: number, filters?: Array<Filter>): Promise<ApiResponse<Nullable<Array<ActionLog>>>> {
    const info = this.getListInfo();
    const params : PaginatedParams = { ...info.defaultParams };
  
    if (itemsPerPage) {
      params.pageSize = itemsPerPage;
    }
  
    if (skip) {
      const adaptSkipToPage = skip / (itemsPerPage || 20);

      params.page = adaptSkipToPage;
    }
  
    if (filters) {
      filters.forEach((filter) => {
        // Now add 'f_' to the filters so the server knowns how to interpret them
        params[filter.id] = filter.value;
        if (filter.id === 'f_name') {
          params.page = 0;
        }
      });
    }

    return API.get(`${this.getBaseUrl()}/events`, { params })
      .then(res => this.handleResponse<Array<ActionLog>>(res));
  }

  autocomplete(stringToComplete: string | undefined): Promise<ApiResponse<Nullable<Array<string>>>> {
    const info = this.getListInfo();
    const params : PaginatedParams = { ...info.defaultParams };

    if (stringToComplete) {
      params.stringToComplete = stringToComplete;
    }

    return API.get(`${this.getBaseUrl()}/autocomplete`, { params })
      .then(res => this.handleResponse<Array<string>>(res));

  }

  getDynamicFilters(): Promise<ApiResponse<Nullable<DynamicFiltersAuditTrail>>> {
    return API.get(`${this.getBaseUrl()}/getdynamicfilters`)
      .then(res => this.handleResponse<DynamicFiltersAuditTrail>(res));
  }

}

export default AdminAuditTrailService;