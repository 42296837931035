import React, { ReactElement } from "react";
import { Box, Button, Input, Stack } from "@mui/material";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import Grid from "@mui/system/Unstable_Grid";
import AssetMgrCreationInterface from "../interface";
import DisplayMorningStarData from "./displaymorningstardata";

interface CreateAssetMgrByMorningStarProps {
  assetMgrCreateForm: AssetMgrCreationInterface,

  handleInputChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, updateIsinCode?: boolean) => void,
  searchInMorningStarAction: () => void,
  clearMorningStarInfoAction: () => void
}

function CreateAssetMgrByMorningStar(props: CreateAssetMgrByMorningStarProps): ReactElement {

  return (
    <Box display='flex' flexDirection='column' gap={2}>

      <h3>Create From MorningStar</h3>
      <Grid container id='CreationFromMorningStarISINCode' direction='row' gap={2}>
        <Box width='200px'>
          {/* field to enter a ISIN Code, with a “Search in MorningStar” button. */}
          <Input
            onChange={event => props.handleInputChange(event, true)}
            value={props.assetMgrCreateForm.morningStarInput?.isinCode}
            placeholder='Enter ISIN Code...'
            fullWidth
          />
        </Box>

        {/* When click, get the info from MorningStar and update the name field on the screen (and MorningStar related information). */}
        <Button
          size='small'
          variant='outlined'
          color='primary'
          disabled={!props.assetMgrCreateForm.morningStarInput?.isIsinValid}
          onClick={() => {
            /* Query to back to fetch from morningStar */
            props.searchInMorningStarAction();
          }}
          startIcon={<SearchRoundedIcon />}
          sx={{ minWidth: '215px' }}>
            Search in MorningStar
        </Button>

        {props.assetMgrCreateForm.morningStarData === null &&
          <>
            <Button
              size='small'
              variant='outlined'
              color='error'
              onClick={() => {
                /* clear any information coming from MorningStar and the Asset Manager will be totally independent of MorningStar. */
                props.clearMorningStarInfoAction();
              }}
              startIcon={<DeleteForeverRoundedIcon />}
              sx={{ minWidth: '215px' }}>
                Clear MorningStar Information
            </Button>
          </>
        }
      </Grid>

      {props.assetMgrCreateForm.morningStarData &&
        <Stack id='DisplayMorningStarResult'>
          <h3>Found from MorningStar</h3>
          {props.assetMgrCreateForm.morningStarData &&
            <DisplayMorningStarData
              data={props.assetMgrCreateForm.morningStarData}
              allowedProperties={['name', 'morningStar', 'morningStar.brandingId', 'morningStar.brandingName']}
            />
          }
        </Stack>
      }
    </Box>
    // If no info MorningStar or not relevant, a “Clear MorningStar Information” button will be available.
    // When pressed, it will clear any information coming from MorningStar and the Asset Manager will be totally independent of MorningStar.
  );
}

export default CreateAssetMgrByMorningStar;