import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Chip, darken, Grid, ListItem, ListItemIcon, Stack, TextField, Tooltip, Typography } from '@mui/material';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { useKeycloak } from '@react-keycloak/web';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { green } from '@mui/material/colors';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { useParams } from 'react-router-dom';
import { Tag } from '@deecision/deeligenz-interfaces';
import EntityIcon from '../entityicon/entityicon';
import { checkAllRoles } from '../../authentification/checkRoles';
import { CustomIconButton } from '../../button/button';
import { StrategyService } from '../../../services/strategy';
import { setUpdateHook } from '../../../hooks/datatabs';
import { AssetMgrService } from '../../../services/assetmgr';

function HeaderTitle(props: {type: string, name: string, date?: string, wide?: boolean, toggleExpand: () => void, tags?: Tag[], children?: ReactElement | ReactElement[]}): ReactElement {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const { id } = useParams();
  const update = setUpdateHook();
  const [copied, setCopied] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [newName, setNewName] = useState(props.name);
  const titleRef = useRef<HTMLDivElement>(null);
  const [titleWidth, setTitleWidth] = useState(200);
  const [disableInput, setDisableInput] = useState(false);
  const updatedNameServices = {
    'strategy': new StrategyService(),
    'assetMgr': new AssetMgrService()
  };

  const applyNewName = (serviceName: string) => {
    if (id) {
      setDisableInput(true);
      updatedNameServices[serviceName as 'strategy' | 'assetMgr'].setName(id, newName)
        .then(() => {
          setEditMode(false);
          setDisableInput(false);
          update(true);
        })
        .catch(() => {
          setEditMode(false);
          setDisableInput(false);
        });
    }
  };

  useEffect(() => {
    if (titleRef.current && titleRef.current.offsetWidth > 200) {
      setTitleWidth(titleRef.current.offsetWidth);
    }
  }, [titleRef]);

  return (
    <Grid item xs onClick={props.toggleExpand} style={{ overflow: 'auto' }}>
      <Grid container spacing={1} style={{ width: 'max-content' }}>
        <Grid item>
          <ListItem style={{ paddingLeft: '12px' }}>
            {props.type &&
            <ListItemIcon style={{ marginRight: '-20px' }}>
              <EntityIcon type={props.type} />
            </ListItemIcon>
            }
            {!editMode ?
              <Typography ref={titleRef} variant='h1' style={{ whiteSpace: 'nowrap' }}>{props.name}</Typography> :
              <TextField
                sx={{
                  width: titleWidth
                }}
                onClick={event => event.stopPropagation()}
                variant='standard'
                value={newName}
                onChange={event => setNewName(event.target.value)}
              />
            }
            {(props.date && !editMode) ?
              <Typography variant='h3' style={{ marginLeft: 'auto' }}>{props.date}</Typography> :
              undefined
            }
            {!editMode &&
              <Tooltip title={copied ? t('utils.copied') : t('utils.copyName')} arrow>
                <Box
                  onClick={(event) => {
                    event.stopPropagation();
                    setCopied(true);

                    return navigator.clipboard.writeText(props.name);
                  }}
                  onMouseLeave={() => setCopied(false)}
                >
                  <FileCopyOutlinedIcon
                    style={{
                      marginBottom: '-6px',
                      marginLeft: '2px',
                      transform: 'scale(0.6)',
                      color: darken('#fff', 0.4)
                    }}
                  />
                </Box>
              </Tooltip>
            }
            {props.tags && props.tags.length > 0 &&
              <Stack direction='row' spacing={1}>
                {props.tags.map(tag => (
                  <Tooltip key={`${tag.value}-${tag.family}`} title={tag.family} arrow placement='top'>
                    <Chip label={tag.label} size='small' color={tag.label === 'gpsl' ? 'success' : undefined} />
                  </Tooltip>
                ))}
              </Stack>
            }
            {checkAllRoles(['admin'], keycloak) && (props.type === 'strategy' || props.type === 'assetMgr') && (editMode ?
              <div style={{ marginLeft: '8px' }}>
                <CustomIconButton
                  variant='text'
                  style={{ color: green[500] }}
                  onClick={(event) => {
                    event.stopPropagation();
                    applyNewName(props.type);
                  }}
                  small
                  disabled={disableInput}
                >
                  <CheckRoundedIcon />
                </CustomIconButton>
                <CustomIconButton
                  variant='text'
                  color='secondary'
                  onClick={(event) => {
                    event.stopPropagation();
                    setEditMode(false);
                    setNewName(props.name);
                  }}
                  small
                  disabled={disableInput}
                >
                  <CloseRoundedIcon />
                </CustomIconButton>
              </div> :
              <CustomIconButton
                variant='text'
                style={{ color: darken('#fff', 0.4) }}
                onClick={(event) => {
                  event.stopPropagation();
                  setEditMode(true);
                }}
                small
              >
                <EditRoundedIcon fontSize='small' />
              </CustomIconButton>
            )}
          </ListItem>
        </Grid>
        {props.children}
      </Grid>
    </Grid>
  );
}

export default HeaderTitle;
