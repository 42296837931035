import React from "react";
import { Box, Typography } from "@mui/material";

function DisplayMorningStarData(props: { data: object, allowedProperties: string[] }) {
  const renderChips = (obj: object, prefix: string = '') =>
    Object.entries(obj).map(([key, value]) => {
      const fullKey = prefix ? `${prefix}.${key}` : key;

      if (!props.allowedProperties.includes(fullKey)) {
        return null; // Skip if the property is not in the allowed list
      }

      if (typeof value === 'object' && value !== null) {
        // Recursively render for nested objects
        return (
          <Box key={fullKey}>
            {renderChips(value, fullKey)}
          </Box>
        );
      }
    
      return (
        value ?
          <Typography key={fullKey}>{key}: <span style={{ fontWeight: 600 }}>{String(value)}</span></Typography>
          : <></>
      );
    });

  return (
    <Box>
      {renderChips(props.data)}
    </Box>
  );
}

export default DisplayMorningStarData;