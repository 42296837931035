import React, { ReactElement, useEffect, useState } from "react";
import { Box, Button, FormControlLabel, FormGroup, Input, SelectChangeEvent, Stack, Switch } from "@mui/material";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { useNavigate } from "react-router-dom";
import { Strategy } from "@deecision/deeligenz-interfaces";
import { StrategyService } from "../../services/strategy";
import { MorningStarService } from "../../services/morningStar";
import DisplayMorningStarData from "./create/assetMgr/components/displaymorningstardata";
import { FundService } from "../../services/fund";

interface AddFundFormInterface {
  strategyIdAssociated: string,
  fromMorningStar: boolean,
  name: string,
  fund: {
    isinCode: string,
    morningStarSearchData: MorningStarFund | undefined | null
  },
  dataReady: boolean
}

interface MorningStarFund {
  name: string,
  legalStructure: string,
  country: string,
  morningStar: {
    id: string,
    categoryId: string,
    strategyId: string,
    providerId: string,
    brandingId: string,
    brandingName: string
  },
  assetClass: string,
  geography: string,
  subAssetClass: string,
  primaryBenchmark: { holdingId: string, securityName: string }
}

function AddFundsToStrategy(): ReactElement {
  const navigate = useNavigate();
  const strategyService = new StrategyService();
  const fundService = new FundService();
  const morningStarService = new MorningStarService();
  const associatedStrategyId = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);
  const [strategyData, setStrategyData] = useState<Strategy>();
  const [addFundForm, setAddFundForm] = useState<AddFundFormInterface>({
    strategyIdAssociated: associatedStrategyId,
    fromMorningStar: false,
    name: '',
    fund: {
      isinCode: '',
      morningStarSearchData: undefined
    },
    dataReady: false
  });

  // Refacto this ?
  const handleInputChangeByProperty = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>, property: string) => {
    setAddFundForm((prevState) => {
      const isReady = prevState.strategyIdAssociated.length > 0 && property === 'name' && event.target.value.length > 0;

      if (property === 'isinCode') {
        return ({
          ...prevState,
          fund: {
            ...prevState.fund,
            isinCode: event.target.value
          }
        });
      }
  
      return ({
        ...prevState,
        [property]: event.target.value,
        dataReady: isReady // Set dataReady to enable the create button when all properties have been filled.
      });
    });
  };

  const handleSwitchChange = () => {
    setAddFundForm(prevState => ({
      ...prevState,
      fromMorningStar: !prevState.fromMorningStar,
      fund: {
        isinCode: '',
        morningStarSearchData: undefined
      },
      name: '',
      dataReady: false
    }));
  };

  const searchInfoInMorningStar = (isinCode: string) => {
    morningStarService.getInfoFromMorningStar(isinCode)
      .then((res) => {
        if (res.data) {
          const name = (res.data?.fund as MorningStarFund).name;

          setAddFundForm(prevState =>
            ({
              ...prevState,
              name,
              fund: {
                ...prevState.fund,
                isinCode,
                morningStarSearchData: (res.data?.fund as MorningStarFund)
              },
              dataReady: name.length > 0
            })
          );
        }
      });
  };

  const onCreateStrategy = async () => {
    const createdFund = await fundService.createAndAddFundToStrategy(
      addFundForm.strategyIdAssociated,
      addFundForm.name,
      addFundForm.fromMorningStar,
      addFundForm.fund.morningStarSearchData,
      addFundForm.fund.isinCode
    );

    navigate(`/funds/${createdFund.data?._id}/dds`);
  };

  useEffect(() => {
    strategyService.getEntity(associatedStrategyId)
      .then((res) => {
        if (res.data) {
          setStrategyData(res.data);
        }
      });
  }, []);

  return (
    <Stack spacing={2} pb={8}>
      <h2>Create Fund And Link To {strategyData?.name}</h2>

      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              color='primary'
              value={addFundForm.fromMorningStar}
              checked={addFundForm.fromMorningStar}
              onChange={handleSwitchChange}
            />
          }
          label='Create From MorningStar'
          style={{ width: '220px' }}
        />
      </FormGroup>

      <>
        <h3>Fund name</h3>
        <Box width='430px'>
          <Input
            onChange={(event) => {
              handleInputChangeByProperty(event, 'name');
            }}
            value={addFundForm.name}
            placeholder='Enter Fund Name...'
            fullWidth
          />
        </Box>
      </>
      {addFundForm.fromMorningStar &&
        <>
          <h3>Create From MorningStar:</h3>
          <Box width='630px' display='flex' flexDirection='row' gap={2}>
            <Box width='200px'>
              <Input
                onChange={(event) => {
                  handleInputChangeByProperty(event, `isinCode`);
                }}
                value={addFundForm.fund.isinCode}
                placeholder='Enter ISIN code...'
                fullWidth
              />
            </Box>

            <Button
              size='small'
              variant='outlined'
              color='primary'
              onClick={() => searchInfoInMorningStar(addFundForm.fund.isinCode)}
              startIcon={<SearchRoundedIcon />}
              sx={{ minWidth: '215px' }}
            >
              Search in MorningStar
            </Button>
          </Box>
          {addFundForm.fund.morningStarSearchData &&
            <>
              <h3>Found From MorningStar:</h3>
              <DisplayMorningStarData
                data={addFundForm.fund.morningStarSearchData}
                allowedProperties={['name', 'country']} // 'assetClass', 'geography', 'subAssetClass'
              />
            </>
          }
        </>
      }

      <Button
        size='small'
        variant='outlined'
        color='primary'
        onClick={onCreateStrategy}
        disabled={!addFundForm.dataReady}
        sx={{
          minWidth: '215px',
          maxWidth: '430px'
        }}>
          Add Fund To Strategy
      </Button>

    </Stack>
  );
}

export default AddFundsToStrategy;