import { API } from '../api/api';
import { ApiResponse, Fund, Nullable } from '../types/types';
import { BaseEntityService } from './data';

export class FundService extends BaseEntityService<Fund> {
  constructor() {
    super();
  }

  getBaseUrl() : string {
    return '/api/v1/funds';
  }
  
  createAndAddFundToStrategy(strategyId: string, name: string, useMorningStar: boolean, morningStarSearchData: unknown | undefined | null, originIsinCode: string): Promise<ApiResponse<Nullable<Fund>>> {
    const params = {
      strategyId,
      name,
      useMorningStar,
      morningStar: morningStarSearchData,
      originIsinCode
    };

    return API.post(`${this.getBaseUrl()}/`, params)
      .then(res => this.handleResponse<Nullable<Fund>>(res));
  }
}

export default FundService;
