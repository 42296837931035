import { BaseEntityService, FundAndMandateProvider } from './data';
import { ApiResponse, Fund, Mandate, Nullable, Strategy } from '../types/types';
import { API } from '../api/api';

export type AssetData = {
  assetClass: (string | null)[],
  geography: (string | null)[],
  subAssetClass: (string | null)[]
};

export class StrategyService extends BaseEntityService<Strategy> implements FundAndMandateProvider {
  constructor() {
    super();
  }

  getBaseUrl() : string {
    return '/api/v1/strategies';
  }

  getFunds(strategyId: string): Promise<ApiResponse<Nullable<Array<Fund>>>> {
    return API.get(`${this.getBaseUrl()}/${strategyId}/funds`, { params: { limit: 1000 } })
      .then(res => this.handleResponse<Array<Fund>>(res));
  }

  getMandates(strategyId: string): Promise<ApiResponse<Nullable<Array<Mandate>>>> {
    return API.get(`${this.getBaseUrl()}/${strategyId}/mandates`, { params: { limit: 1000 } })
      .then(res => this.handleResponse<Array<Mandate>>(res));
  }

  setName(strategyId: string, name: string): Promise<ApiResponse<Nullable<unknown>>> {
    return API.put(`${this.getBaseUrl()}/${strategyId}/name`, { name })
      .then(res => this.handleResponse<Nullable<unknown>>(res));
  }

  getUniqueStrategyProperties(strategyId: string): Promise<ApiResponse<Nullable<{data: AssetData}>>> {
    return API.get(`${this.getBaseUrl()}/${strategyId}/uniqueStrategyProperties`)
      .then(res => this.handleResponse<Nullable<{data: AssetData}>>(res));
  }

  createStrategyAndLinkToOtherEntities(associatedAssetMgrId: string, strategyName: string, assetClass: string | null, subAssetClass: string | null, geography: string | null): Promise<ApiResponse<Nullable<Strategy>>>{
    const params = {
      associatedAssetMgrId,
      strategyData: {
        assetMgr: associatedAssetMgrId,
        name: strategyName,
        assetClass,
        subAssetClass,
        geography
      }
    };
    
    return API.post(`${this.getBaseUrl()}/createStrategy`, params)
      .then(res => this.handleResponse<Nullable<Strategy>>(res));
  }

  createAndAddFundToStrategy(strategyId: string, name: string, useMorningStar: boolean, morningStarSearchData: unknown | undefined | null, originIsinCode: string): Promise<ApiResponse<Nullable<Fund>>> {
    const params = {
      strategyId,
      name,
      useMorningStar,
      morningStar: morningStarSearchData,
      originIsinCode
    };

    return API.post(`${this.getBaseUrl()}/createAndAddFundToStrategy`, params)
      .then(res => this.handleResponse<Nullable<Fund>>(res));
  }
}

export default StrategyService;
