import React, { ReactElement } from "react";
import Grid from "@mui/system/Unstable_Grid";
import { Box, Stack } from "@mui/material";
import { ActionLog } from "@deecision/deeligenz-interfaces";
import CustomTable, { Column } from "../../table/table";
import { ActionTypeColor } from "../../audittrail/audittrailenumtype";
import { FilterParamsManager } from "../../entity/list";
import DateUtilsConverter from "../../../utils/dateconverter";
import SecureDisplay from "../../authentification/securedisplay";
import EntityLink from "../../modularcomponents/entitylink/entitylink";

interface AuditTrailTableProps {
    data: ActionLog[],
    paramsSort?: FilterParamsManager
}

function AdminAuditTrailTable(props: AuditTrailTableProps): ReactElement {
  const columns: Array<Column> = [
    { id: 'entity.type', label: 'Entity Type', minWidth: 'min-content', align: 'center' },
    { id: 'name', label: 'Entity Name', minWidth: 'min-content', align: 'center' },
    { id: 'action.type', label: 'Action', minWidth: 'min-content', align: 'center' },
    { id: 'action.targetType', label: 'Target Type', minWidth: 'min-content', align: 'center' },
    { id: 'scope', label: 'Scope', minWidth: 'min-content', align: 'center' },
    { id: 'action.description', label: 'Description', minWidth: 'min-content', align: 'center' },
    { id: 'actor.name', label: 'User', minWidth: 'min-content', align: 'center' },
    { id: 'timestamp', label: 'Timestamp', minWidth: 'min-content', align: 'center' }
  ];
  const rows = props.data?.map((event: ActionLog) => ({
    id: event.id,
    value: columns.map(column => (
      <>
        {column.id === 'entity.type' && event.entity.type}
        {column.id === 'name' &&
          <SecureDisplay
            permissions='details'
            optionalChild={<>{event.entity.name}</>}
          >
            <EntityLink type={event.entity.type} id={event.entity?.id} name={event.entity.name} center={column.align === 'center'} openInNewTabIcon iconAlwaysDisplay />
          </SecureDisplay>
        }
        {column.id === 'action.type' &&
          <Stack justifyContent='center' alignItems='center' direction='row' spacing={1}>
            <span style={{ color: ActionTypeColor[event.action.type] }}>{event.action.type}</span>
          </Stack>
        }
        {column.id === 'action.targetType' &&
          <Stack justifyContent='center' alignItems='center' direction='row' spacing={1}>
            <span>{event.action.targetType}</span>
          </Stack>
        }
        {column.id === 'scope' &&
          <Box>
            {event.scope === 'due_diligence' ?
              <EntityLink type='dueDiligence' id={`${event.dd.id}`} name={event.scope} center={column.align === 'center'} openInNewTabIcon iconAlwaysDisplay />
              :
              event.scope
            }
          </Box>
        }
        {column.id === 'action.description' && event.action.description }
        {column.id === 'timestamp' &&
          <span>{DateUtilsConverter((new Date(event.timestamp)).toISOString(), 'Europe/Paris', 'ISO')}</span>
        }
        {column.id === 'actor.name' && event.actor?.name}
      </>
    ))
  }));

  return (
    <Grid container xs={12} p={1} pb={4} spacing={2}>
      <CustomTable columns={columns} rows={rows} size='small' paramsSort={props.paramsSort} nonScrollableY/>
    </Grid>
  );
}

export default AdminAuditTrailTable;