import { ActionLog } from "@deecision/deeligenz-interfaces";
import AdminAuditTrailService from "../../services/AdminAuditTrailService";
import { EntityListDataProvider } from "../entity/list";
import { SearchParamsAccess } from "../params";

class AdminAuditTrailDataProvider extends EntityListDataProvider<ActionLog> {
  constructor( entityType: string, searchParamsAccess: SearchParamsAccess, id: string, itemsPerPageMin?: number) {
    super(searchParamsAccess,  new AdminAuditTrailService(entityType, id), itemsPerPageMin);
  }
}

export default AdminAuditTrailDataProvider;