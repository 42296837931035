import React, { ReactElement } from 'react';
import { Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Nullable, Strategy } from '@deecision/deeligenz-interfaces';
import CustomButton from '../button/button';

function RelocateSection(props: {entity: Nullable<Strategy> }): ReactElement {
  const navigate = useNavigate();

  return (
    <>
      { props.entity &&
        <Grid container spacing={2} alignItems='center' pb={2}>
          <Grid item xs={12}>
            <Typography variant='subtitle1'>Relocate</Typography>
          </Grid>
          <Grid item>
            <CustomButton
              onClick={() => navigate(`/admin/relocateStrategy/${props.entity?._id}`)}
              style={{
                marginRight: 0,
                whiteSpace: 'nowrap',
                alignSelf: 'center'
              }}
              variant='outlined'
              color='primary'
              small
            >
              Relocate AssetMgr
            </CustomButton>
          </Grid>
        </Grid>
      }
    </>
  );
}

export default RelocateSection;
