import React, { ReactElement, useState } from "react";
import { Box, Button, FormControlLabel, FormGroup, Input, Stack, Switch } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CreateAssetMgrByMorningStar from "./components/createbymorningstar";
import { AssetMgrService } from "../../../../services/assetmgr";
import isValidISIN from "../../../../utils/isisincode";
import AssetMgrCreationInterface from "./interface";
import { MorningStarService } from "../../../../services/morningStar";

interface MorningStarAssetMgr {
  name: string,
  morningStar: {
    globalBrandingCompanyId: string,
    brandingId: string,
    brandingName: string
  }
}

function CreateAssetMgr(): ReactElement {
  const navigate = useNavigate();
  const morningStarService = new MorningStarService();
  const [assetMgrCreateForm, setAssetMgrCreateForm] = useState<AssetMgrCreationInterface>({
    configurationFromMorningStar: false,
    assetMgrName: '',
    morningStarData: undefined,
    dataReady: false
  });
  const assetMgrService = new AssetMgrService();

  const handleMorningStarCheckboxChange = () => {
    // set configurationMode and Reset form upon changing creation mode
    setAssetMgrCreateForm(prevState => ({
      configurationFromMorningStar: !prevState.configurationFromMorningStar,
      assetMgrName: '',
      morningStarData: undefined,
      dataReady: false
    }));
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, updateIsinCode?: boolean) => {
    // Update userInput depending on creation mode
    if (updateIsinCode) {
      // From Morning Star
      setAssetMgrCreateForm(prevState => ({
        ...prevState,
        morningStarInput: {
          isinCode: event.target.value,
          isIsinValid: isValidISIN(event.target.value)
        }
      }));
    } else {
      // From Manual input
      setAssetMgrCreateForm(prevState => ({
        ...prevState,
        assetMgrName: event.target.value,
        dataReady: event.target.value.length > 0
      }));
    }
  };

  const searchInMorningStarAction = () => {
    if (assetMgrCreateForm.morningStarInput?.isinCode && isValidISIN(assetMgrCreateForm.morningStarInput?.isinCode)) {
      morningStarService.getInfoFromMorningStar(assetMgrCreateForm.morningStarInput?.isinCode)
        .then((res) => {
          if (res.data) {
            setAssetMgrCreateForm(prevState => ({
              ...prevState,
              assetMgrName: (res.data?.assetMgr as MorningStarAssetMgr).name,
              morningStarData: (res.data?.assetMgr as MorningStarAssetMgr),
              dataReady: true
            }));
          }
        });
      
    } else {
      alert('[Wrong format] - Isin code is not valid.');
    }
  };

  const clearMorningStarInfoAction = () => {
    setAssetMgrCreateForm(prevState => ({
      ...prevState,
      morningStarData: undefined
    }));
  };

  const createAssetMgr = async () => {
    // DEEL-1129: name of the Asset Manager + TODO: check if name already exist
    const assetMgrCreated = await assetMgrService.createNewAssetMgr(
      assetMgrCreateForm.assetMgrName,
      assetMgrCreateForm.morningStarData?.morningStar,
      assetMgrCreateForm.configurationFromMorningStar,
      assetMgrCreateForm.morningStarInput?.isIsinValid && assetMgrCreateForm.morningStarInput?.isinCode
    );

    navigate(`/assetmgrs/${assetMgrCreated.data?._id}/`);
  };

  return (
    <Stack spacing={2} pb={6}>
      <h2>Create New Asset Manager</h2>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              color='primary'
              value={assetMgrCreateForm.configurationFromMorningStar}
              checked={assetMgrCreateForm.configurationFromMorningStar}
              onChange={handleMorningStarCheckboxChange}
            />
          }
          label='Create From MorningStar'
          style={{ width: '220px' }}
        />
      </FormGroup>

      <>
        <h3>Asset Manager name</h3>
        <Box width='430px'>
          <Input
            onChange={handleInputChange}
            value={assetMgrCreateForm.assetMgrName}
            placeholder='Enter Asset Manager Name...'
            fullWidth
          />
        </Box>
      </>

      {assetMgrCreateForm.configurationFromMorningStar &&
        <CreateAssetMgrByMorningStar
          assetMgrCreateForm={assetMgrCreateForm}
          handleInputChange={handleInputChange}
          searchInMorningStarAction={searchInMorningStarAction}
          clearMorningStarInfoAction={clearMorningStarInfoAction}
        />
      }

      <Button
        size='small'
        variant='outlined'
        color='primary'
        onClick={createAssetMgr}
        disabled={!assetMgrCreateForm.dataReady}
        sx={{
          minWidth: '215px',
          maxWidth: '430px',
          maxHeight: '50px'
        }}>
          Create Asset Manager
      </Button>

    </Stack>
  );
}

export default CreateAssetMgr;