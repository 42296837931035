import React, { ReactElement, useEffect, useState } from "react";
import { Autocomplete, Box, Button, FormControl, Stack, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Strategy } from "@deecision/deeligenz-interfaces";
import { StrategyService } from "../../services/strategy";
import ValidationModal from "../layout/modals/validationmodal";

function RelocateStrategyPage(): ReactElement {
  const navigate = useNavigate();
  const strategyService = new StrategyService();
  const strategyIdToRelocate = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);
  const [strategyData, setStrategyData] = useState<Strategy>();
  const [realocatableEntitiesList, setRealocatableEntitiesList] = useState<{_id: string, name: string}[]>([]);
  const [selectedEntityToRealocate, setSelectedEntityToRealocate] = useState<{_id: string, name: string} | null>(null);
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);

  const onRelocate = async () => {
    const oldAssetMgrId = strategyData?.assetMgr?._id;
    const newAssetMgrId = selectedEntityToRealocate?._id;
    if (oldAssetMgrId && newAssetMgrId) {
      await strategyService.relocateStrategy(strategyIdToRelocate, oldAssetMgrId, newAssetMgrId);
    }

    navigate(`/strategies/${strategyIdToRelocate}/dds`);
  };

  useEffect(() => {
    strategyService.getEntity(strategyIdToRelocate)
      .then((res) => {
        if (res.data) {
          setStrategyData(res.data);
        }
      });
    strategyService.getRelocateEntitiesList(strategyIdToRelocate)
      .then((res) => {
        if (res.data) {
          setRealocatableEntitiesList(res.data);
          console.log(res.data.map(assetMgrName => assetMgrName.name));
        }
      });
  }, []);

  return (
    <Stack spacing={2} pb={8}>
      <h2>{`Relocate this Strategy "${strategyData?.name}" to a new Asset Manager`}</h2>

      <Typography variant='body1'>When relocating a strategy to a new asset manager, all the strategy related funds and mandates will also be relocated to the new asset manager.</Typography>
      <Box width='400px' py={1}>
        <FormControl fullWidth>
          <Autocomplete
            options={realocatableEntitiesList.map(assetMgrName => assetMgrName.name).filter((item, index) => realocatableEntitiesList.map(assetMgrName => assetMgrName.name).indexOf(item) === index)}
            renderInput={params => <TextField {...params} label='Select Asset Manager' />}
            onChange={(event, value) => {
              const selectedEntityObj = realocatableEntitiesList.find(entity => entity.name === value) || null;
              setSelectedEntityToRealocate(selectedEntityObj);
            }}
          />
        </FormControl>
      </Box>

      <Button
        size='small'
        variant='outlined'
        color='primary'
        onClick={() => {
          setOpenConfirmationModal(!openConfirmationModal);
        }}
        disabled={!selectedEntityToRealocate}
        sx={{
          minWidth: '215px',
          maxWidth: '430px'
        }}>
          Relocate
      </Button>

      <ValidationModal
        action={onRelocate}
        cancel={() => {
          setOpenConfirmationModal(false);
        }}
        open={openConfirmationModal}
        msg={`Do you want to relocate "${strategyData?.name}" to "${selectedEntityToRealocate?.name}" ?`}
      />

    </Stack>
  );
}

export default RelocateStrategyPage;