import React, { ReactElement } from 'react';
import { Grid } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import Aums from '../../../../components/aum/aums';
import { strategyContext, setUpdateHook } from '../../../../hooks/datatabs';
import NextReviewParameters from '../../../../components/nextreviews/nextreviewparameters';
import AuditedEntitiesService from '../../../../services/auditedentities';
import RelocateSection from '../../../../components/params/relocatesection';
import checkRoles from '../../../../components/authentification/checkRoles';

function Parameters(): ReactElement {
  const entity = strategyContext();
  const update = setUpdateHook();
  const keycloak = useKeycloak();
  const isAdmin = checkRoles(['admin'], keycloak.keycloak);
  const entityService = entity ? new AuditedEntitiesService('strategy', entity._id) : undefined;

  return (
    <>
      { entity && entityService &&
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Aums name={entity.name} aumService={entityService} aum={entity.aum} update={() => update(true)} entity />
          </Grid>
          <Grid item xs={12}>
            <NextReviewParameters nextReviews={entity.nextReviews} service={entityService} update={() => update(true)} />
          </Grid>
          {isAdmin &&
            <Grid item xs={12}>
              <RelocateSection entity={entity} />
            </Grid>
          }
        </Grid>
      }
    </>
  );
}

export default Parameters;
