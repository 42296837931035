import React, { ReactElement } from 'react';
import FileCopyRoundedIcon from '@mui/icons-material/FileCopyRounded';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import InsertChartRoundedIcon from '@mui/icons-material/InsertChartRounded';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import FolderSharedRoundedIcon from '@mui/icons-material/FolderSharedRounded';
import AssignmentReturnedRoundedIcon from '@mui/icons-material/AssignmentReturnedRounded';
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import { SvgIconComponent } from '@mui/icons-material/';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import ManageSearchRoundedIcon from '@mui/icons-material/ManageSearchRounded';
import MandatesIcon from '../../../themes/icon/mandateicon';
import StrategiesIcon from '../../../themes/icon/strategiesicon';

interface Entity {
  type: string,
  icon: SvgIconComponent
}

const entities: Array<Entity> = [
  { type: 'fund', icon: InsertChartRoundedIcon },
  { type: 'assetMgr', icon: AccountBalanceRoundedIcon },
  { type: 'investMgr', icon: BusinessRoundedIcon },
  { type: 'dueDiligence', icon: FileCopyRoundedIcon },
  { type: 'due diligence', icon: FileCopyRoundedIcon }, // DEPRECATED ! DO NOT USE
  { type: 'mandate', icon: MandatesIcon as SvgIconComponent },
  { type: 'strategy', icon: StrategiesIcon as SvgIconComponent },
  { type: 'auditee', icon: SearchRoundedIcon },
  { type: 'admin', icon: FolderSharedRoundedIcon },
  { type: 'import', icon: AssignmentReturnedRoundedIcon },
  { type: 'ddElementGroups', icon: AssignmentReturnedRoundedIcon },
  { type: 'ddTemplates', icon: AssignmentReturnedRoundedIcon },
  { type: 'importTemplate', icon: AssignmentReturnedRoundedIcon },
  { type: 'importGPSL', icon: ListAltRoundedIcon },
  { type: 'importAUM', icon: AssignmentReturnedRoundedIcon },
  { type: 'auditTrail', icon: ManageSearchRoundedIcon },
  { type: 'settings', icon: SettingsRoundedIcon }
];

function getTypeInfo(type: string) {
  return entities.find(e => e.type === type);
}

function EntityIcon(props: {type: string, size?: string}): ReactElement {
  const typeInfo = getTypeInfo(props.type);

  if (props.size === "large") {
    return (
      <>
        {typeInfo && <typeInfo.icon fontSize='large' />}
        {/* {typeInfo && <typeInfo.icon style={{width: '100%', height: '100%'}}/>} */}
      </>
    );
  }

  return (
    <>
      {typeInfo && <typeInfo.icon  />}
      {/* {typeInfo && <typeInfo.icon style={{width: '100%', height: '100%'}}/>} */}
    </>
  );
}

export default EntityIcon;
