import React, { ReactElement, useEffect, useState } from "react";
import { Box, Button, FormControl, Input, MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AssetMgrData } from "@deecision/deeligenz-interfaces";
import { AssetData, StrategyService } from "../../services/strategy";
import { AssetMgrService } from "../../services/assetmgr";

interface StrategyCreateFormInterface {
  assetMgrIdAssociated: string,
  strategyName: string,
  assetClass: string,
  subAssetClass: string,
  geography: string,
  dataReady: boolean
};

function AddStrategyToAssetMgr(): ReactElement {
  const navigate = useNavigate();
  const strategyService = new StrategyService();
  const assetMgrService = new AssetMgrService();
  const associatedAssetMgrId = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);
  const [uniqueSelectProperties, setUniqueSelectProperties] = useState<AssetData>();
  const [assetMgrData, setAssetMgrData] = useState<AssetMgrData>();
  const [strategyCreateForm, setStategyCreateForm] = useState<StrategyCreateFormInterface>({
    assetMgrIdAssociated: associatedAssetMgrId,
    strategyName: '',
    assetClass: '',
    subAssetClass: '',
    geography: '',
    dataReady: false
  });

  const handleInputChangeByProperty = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>, property: string) => {
    setStategyCreateForm(prevState => ({
      ...prevState,
      [property]: event.target.value,
      dataReady: (prevState.strategyName.length > 0 && prevState.assetMgrIdAssociated.length > 0)
    }));
  };

  const onCreateStrategy = async () => {
    const strategyCreated = await strategyService.createStrategyAndLinkToOtherEntities(
      strategyCreateForm.assetMgrIdAssociated,
      strategyCreateForm.strategyName,
      strategyCreateForm.assetClass === "" ? null : strategyCreateForm.assetClass,
      strategyCreateForm.subAssetClass === "" ? null : strategyCreateForm.subAssetClass,
      strategyCreateForm.geography === "" ? null : strategyCreateForm.geography
    );

    navigate(`/strategies/${strategyCreated.data?._id}`);
  };

  useEffect(() => {
    // Get uniqueProperties for assetclass, subAssetClass, Geography
    strategyService.getUniqueStrategyProperties('623c9f3ddfa9cc0037122955') // TODO: See if this hardcoded can be removed
      .then((res) => {
        if (res.data) {
          // Build data by filtering out empty or null value, then set a null value for each at start for user to select none
          // some array of object may contains or not a null value so we have to harmonize them all first
          setUniqueSelectProperties({
            assetClass: [null, ...(res.data.data.assetClass.filter(item => item != null && item !== ''))],
            subAssetClass: [null, ...(res.data.data.subAssetClass.filter(item => item != null && item !== ''))],
            geography: [null, ...(res.data.data.geography.filter(item => item != null && item !== ''))]
          });
        }
      });
    // Used to get assetMgr Name to display
    assetMgrService.getEntity(associatedAssetMgrId)
      .then((res) => {
        if (res.data) {
          setAssetMgrData(res.data);
        }
      });
  }, []);

  useEffect(() => {
    if (
      strategyCreateForm.assetClass !== "" && strategyCreateForm.subAssetClass!== "" &&
      strategyCreateForm.geography!== "" && strategyCreateForm.strategyName.length > 0 &&
      strategyCreateForm.assetMgrIdAssociated.length > 0
    ) {
      setStategyCreateForm(prevState => ({
        ...prevState,
        dataReady: true
      }));
    }
  }, [strategyCreateForm]);

  return (
    <Stack spacing={2} pb={8}>
      {/* TODO: Add a navigate on the name to the id */}
      <h2>Create and add a Strategy to associate with {assetMgrData?.name}</h2>

      <h3>Provide Strategy Information:</h3>
      <Box width='400px'>
        <Typography variant='h5'>Name</Typography>
        <Input
          onChange={(event) => {
            handleInputChangeByProperty(event, 'strategyName');
          }}
          value={strategyCreateForm.strategyName}
          placeholder='Enter Strategy name...'
          fullWidth
        />
      </Box>

      <Box width='400px' py={1}>
        <Typography variant='h5'>Asset Class</Typography>
        <FormControl fullWidth>
          <Select
            variant='standard'
            value={strategyCreateForm.assetClass}
            label='Asset Class'
            onChange={(event) => {
              handleInputChangeByProperty(event, 'assetClass');
            }}
            fullWidth
          >
            {uniqueSelectProperties?.assetClass.map(value =>
              <MenuItem value={value || ''}>{value || 'N/A'}</MenuItem>)
            }
          </Select>
        </FormControl>
      </Box>

      <Box width='400px' py={1}>
        <Typography variant='h5'>Sub Asset Class</Typography>
        <FormControl fullWidth>
          <Select
            variant='standard'
            value={strategyCreateForm.subAssetClass}
            label='Sub Asset Class'
            onChange={(event) => {
              handleInputChangeByProperty(event, 'subAssetClass');
            }}
            fullWidth
          >
            {uniqueSelectProperties?.subAssetClass.map(value =>
              <MenuItem value={value || ''}>{value || 'N/A'}</MenuItem>)
            }
          </Select>
        </FormControl>
      </Box>

      <Box width='400px'>
        <Typography variant='h5'>Geography</Typography>
        <FormControl fullWidth>
          <Select
            variant='standard'
            value={strategyCreateForm.geography}
            label='Geography'
            onChange={(event) => {
              handleInputChangeByProperty(event, 'geography');
            }}
            fullWidth
          >
            {uniqueSelectProperties?.geography.map(value =>
              <MenuItem value={value || ''}>{value || 'N/A'}</MenuItem>)
            }
          </Select>
        </FormControl>
      </Box>

      <Button
        size='small'
        variant='outlined'
        color='primary'
        onClick={onCreateStrategy}
        disabled={!strategyCreateForm.dataReady}
        sx={{
          minWidth: '215px',
          maxWidth: '400px',
          maxHeight: '50px'
        }}>
          Add Strategy To Asset Manager
      </Button>

    </Stack>
  );
}

export default AddStrategyToAssetMgr;