import React, { ReactElement } from "react";
import Grid from "@mui/system/Unstable_Grid";
import { Stack } from "@mui/material";
import { ActionLog } from "@deecision/deeligenz-interfaces";
import CustomTable, { Column } from "../table/table";
import { ActionTypeColor } from "./audittrailenumtype";
// import AuditTrailDynamicIcon from "./components/auditactionicon";
import { FilterParamsManager } from "../entity/list";
import DateUtilsConverter from "../../utils/dateconverter";

interface AuditTrailTableProps {
    data: ActionLog[],
    paramsSort?: FilterParamsManager
}

function AuditTrailTable(props: AuditTrailTableProps): ReactElement {
  const columns: Array<Column> = [
    { id: 'action.type', label: 'Action', minWidth: 'min-content', align: 'center' },
    { id: 'action.description', label: 'Description', minWidth: 'min-content', align: 'center' },
    { id: 'actor.name', label: 'User', minWidth: 'min-content', align: 'center' },
    { id: 'timestamp', label: 'Timestamp', minWidth: 'min-content', align: 'center' }
  ];
  const rows = props.data?.map((event: ActionLog) => ({
    id: event.id,
    value: columns.map(column => (
      <>
        {column.id === 'action.type' &&
          <Stack justifyContent='center' alignItems='center' direction='row' spacing={1}>
            {/* TODO: FIX THIS because the type has changed */}
            {/* {event.action.type &&
              <AuditTrailDynamicIcon
                iconType={event.action.type}
                color={colorToPalette[ActionTypeColor[event.action.type]] as ColorPalette || 'inherit'}
              />
            } */}
            <span style={{ color: ActionTypeColor[event.action.type] }}>{event.action.type}</span>
          </Stack>
        }
        {column.id === 'action.description' && event.action.description }
        {column.id === 'timestamp' &&
          <span>{DateUtilsConverter((new Date(event.timestamp)).toISOString(), 'Europe/Paris', 'ISO')}</span>
        }
        {column.id === 'actor.name' && event.actor.name}
      </>
    ))
  }));

  return (
    <Grid container xs={12} p={1} pb={4} spacing={2}>
      <CustomTable columns={columns} rows={rows} size='small' paramsSort={props.paramsSort} nonScrollableY/>
    </Grid>
  );
}

export default AuditTrailTable;