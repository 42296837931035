import React, { ReactElement } from 'react';
import { FilterParamsManager } from '../../../components/entity/list';
import GenericFilters from '../../../components/filters/filters';
import { ApiFilter, ParamFilter } from '../../../types/types';
import { FundService } from '../../../services/fund';

export const GeoAssetClassSubAssetClassFilters: Array<ParamFilter> = [
  // Note: values generated by deeligenz-server/proto/testGetAllAssetClasses.js
  { id: 'assetClass', type: 'multiSelect', label: 'Asset Class', values: [
    { label: 'Equities' },
    { label: 'Fixed Income' },
    { label: 'Illiquid Alternatives' },
    { label: 'Liquid Alternatives' },
    { label: 'Multi-Assets' },
    { label: 'Other' }
  ] },
  { id: 'geography', type: 'multiSelect', label: 'Geography', values: [
    { label: 'Asia' },                 { label: 'Asia Pacific Equity' },
    { label: 'Asia ex-Japan Equity' }, { label: 'China' },
    { label: 'Emerging Europe' },      { label: 'Emerging Markets' },
    { label: 'Europe' },               { label: 'European Nordics' },
    { label: 'Eurozone' },             { label: 'France' },
    { label: 'Germany' },              { label: 'Global' },
    { label: 'Greater China' },        { label: 'India' },
    { label: 'Japan' },                { label: 'Latin America' },
    { label: 'Middle East' },          { label: 'Netherlands' },
    { label: 'Nordics' },              { label: 'Other country' },
    { label: 'Other region' },         { label: 'UK' },
    { label: 'US' }
  ] },
  { id: 'subAssetClass', type: 'multiSelect', label: 'Sub Asset Class', values: [
    { label: 'Aggregate' },
    { label: 'Aggressive Allocation' },
    { label: 'Alternative Risk Premia' },
    { label: 'Blend' },
    { label: 'CTA & Quant Macro' },
    { label: 'Capital Protected' },
    { label: 'Cautious Allocation' },
    { label: 'Communications Services' },
    { label: 'Consumer Goods & Services' },
    { label: 'Convertible Arbitrage' },
    { label: 'Convertibles' },
    { label: 'Corporate Bonds' },
    { label: 'Covered' },
    { label: 'Currency' },
    { label: 'Demographic and Social Shifts' },
    { label: 'Distressed' },
    { label: 'Energy' },
    { label: 'Environmental Shifts' },
    { label: 'Event Driven' },
    { label: 'Financials' },
    { label: 'Fixed Income Arbitrage' },
    { label: 'Flexible Allocation' },
    { label: 'Geopolitical Shifts' },
    { label: 'Global Macro' },
    { label: 'Government Bonds' },
    { label: 'Government Bonds HC' },
    { label: 'Government Bonds LC' },
    { label: 'Growth' },
    { label: 'Guaranteed' },
    { label: 'Healthcare' },
    { label: 'High Yield' },
    { label: 'Impact' },
    { label: 'Industrials' },
    { label: 'Infrastructure' },
    { label: 'Long/Short Credit' },
    { label: 'Long/Short Equity' },
    { label: 'MBS/ABS' },
    { label: 'Market Neutral' },
    { label: 'Mid/Small' },
    { label: 'Miscellaneous' },
    { label: 'Moderate Allocation' },
    { label: 'Money Market' },
    { label: 'Multi-strategies' },
    { label: 'Natural Resources' },
    { label: 'Other' },
    { label: 'Other Private Assets' },
    { label: 'Other liquid alternatives' },
    { label: 'Other thematic' },
    { label: 'Precious Metals' },
    { label: 'Private Debt / Loans' },
    { label: 'Private Equity' },
    { label: 'Real Estate' },
    { label: 'Senior Loans' },
    { label: 'Technological Shifts' },
    { label: 'Technology' },
    { label: 'Total Return' },
    { label: 'Utilities' },
    { label: 'Value' },
    { label: 'Volatility Arbitrage' }
  ] }
];

function FundFilters(props: {filterParamsManager: FilterParamsManager, apiFilters?: ApiFilter[]}): ReactElement {
  const filters: Array<ParamFilter> = props.apiFilters ?
    props.apiFilters.map(filter => (
      { id: filter.value, type: 'multiSelect', label: filter.label, values: filter.elements }
    ))
    :
    GeoAssetClassSubAssetClassFilters;

  if (!filters.find(filter => filter.id === 'name')) {
    filters.push({
      id: 'name',
      type: 'search',
      label: 'Search',
      autoComplete: query => new FundService().getSearchList(query)
    });
  }
  if (!filters.find(filter => filter.id === 'singleColsReviews')) {
    filters.push({
      id: 'singleColsReviews',
      type: 'checkbox',
      label: 'Single column for reviews'
    });
  }

  return (<GenericFilters filters={filters} filterParamsManager={props.filterParamsManager} />);
}

export default FundFilters;
